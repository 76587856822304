<template>
    <div class="modal-confirm-paste-image">
        <div class="head flex-row flex-between items-start m-b-24">
            <div class="title" v-html="$translate('MODAL_CONFIRM_PASTE_IMAGE')"/>
            <i @click="$emit('close')" class="zmdi zmdi-close"/>
        </div>
        <div class="body">
            <img :src="imageUrl">
        </div>
        <button
            @click="onConfirm"
            class="btn btn-primary m-l-a"
            v-html="$translate('SEND')"/>
    </div>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'ModalConfirmPasteImage',
    props: ['options'],
    data: () => ({
        imageUrl: null,
    }),
    mounted() {
        this.imageUrl = URL.createObjectURL(this.options.file)
    },
    methods: {
    },
}
</script>